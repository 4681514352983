import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Popover } from 'antd';
import { ReactComponent as PrivateEye } from '@assets/icons/eye-private.svg';
import { ReactComponent as Move } from '@assets/icons/bulkActions/move.svg';
import { ReactComponent as Delete } from '@assets/icons/bulkActions/delete.svg';
import { ReactComponent as Download } from '@assets/icons/bulkActions/download.svg';
import { ReactComponent as AWDefaultSvg } from '@assets/icons/AWButtonStatuses/default.svg';
import { ReactComponent as Share } from '@assets/icons/share.svg';
import { ReactComponent as UpgradePlanSvg } from '@assets/icons/diamond-upgrade.svg';
import { AssetItemDto, SimpleFolderItemDto } from '@api/Api';
import { assetGetSimpleFoldersList } from '@api/Asset';
import './BulkActions.less';
import { useDispatch } from 'react-redux';
import {
  deleteAssetAction,
  moveAssetToFolder,
  restoreDeletedAssetAction
} from '@redux/actions/assetsAction';
import { updateAssetsWorkspaceCounters } from '@redux/actions/workspaceCountersAction';
import {
  toggleMoveAssetToPrivateFolder,
  toggleShareAssetModal,
  toggleApprovalModal,
  toggleApprovalConflictAssetsModal
} from '@redux/actions/modalAction';
import { updateCampaignCounters } from '@redux/actions/campaignAction';
import { downloadAll } from '@helpers/download';
import { useOrganization } from '@components/OrganizationBoundary';
import UpgradePlanTooltip from '@components/Tooltip/UpgradePlanTooltip';
import { AssetsActionTypes } from '@redux/types/assetsType';

function BulkActions({
  selectedAssets,
  onClose,
  page
}: {
  selectedAssets: { node: AssetItemDto; cursor: string }[];
  onClose: () => void;
  page: string;
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentOrganization } = useOrganization();
  const [isMovePopoverVisible, setIsMovePopoverVisible] =
    useState<boolean>(false);
  const [foldersList, setFoldersList] = useState<SimpleFolderItemDto[]>([]);
  const [canBulkMove, setCanBulkMove] = useState<boolean>(false);
  const awFeatureEnabled =
    !!currentOrganization.entity?.features.approvalRouting;

  const {
    canBulkDelete,
    canBulkDownload,
    canBulkShare,
    canBulkApprovalWorkflow
  } = useMemo(() => {
    if (!selectedAssets.length)
      return {
        canBulkDownload: false,
        canBulkDelete: false,
        canBulkShare: false,
        canBulkApprovalWorkflow: false
      };

    const canBulkDownload: boolean = selectedAssets.every(
      (asset: any) => asset?.node?.asset?.versions[0].permissions.download
    );
    const canBulkDelete: boolean = selectedAssets.every(
      (asset: any) => asset?.node?.asset?.permissions.delete
    );
    const canBulkShare: boolean = selectedAssets
      .map(({ node }: any) => ({
        campaignId: node.campaignId,
        canCreateReviews: node.asset.permissions.createReviews
      }))
      .every(
        ({ campaignId, canCreateReviews }: any, _idx: number, array: any) => {
          const canShareWithinCampaign = campaignId === array[0].campaignId;
          const canShareWithinPermissions = canCreateReviews;
          return canShareWithinCampaign && canShareWithinPermissions;
        }
      );

    const canBulkApprovalWorkflow = selectedAssets.every((asset: any) => {
      const canManageAW =
        asset?.node?.asset?.versions[0].permissions.manageApprovalWorkflow;
      return canManageAW;
    });

    return {
      canBulkDownload,
      canBulkDelete,
      canBulkShare,
      canBulkApprovalWorkflow
    };
  }, [selectedAssets, awFeatureEnabled, currentOrganization]);

  const { length } = selectedAssets;
  const upgradeShare =
    length > 1 && !currentOrganization.entity?.features.shareMultipleAssets;
  const canUseBatchApprovals =
    !!currentOrganization.entity?.features.batchApprovals || length === 1;

  useEffect(() => {
    if (!selectedAssets.length) return;
    let canMove = selectedAssets
      .map(({ node }: any) => node.campaignId)
      .every((it: any, _idx: any, array: any) => it === array[0]);

    (async () => {
      if (canMove) {
        const {
          data: { list }
        } = await assetGetSimpleFoldersList({
          campaignId: selectedAssets[0].node.campaignId,
          workspaceId: selectedAssets[0].node.workspaceId
        });
        if (list.length === 0) canMove = false;
        setFoldersList(list);
      }

      setCanBulkMove(canMove);
    })();
  }, [selectedAssets]);

  return (
    <div
      className={
        length > 0
          ? 'bulk_actions_container bulk_actions_container_active'
          : 'bulk_actions_container'
      }
    >
      <div className="bulk_actions_container__body">
        <div className="items">
          <div className="list" />
          <div className="copy">{length} media selected</div>
        </div>
        <div className="close" onClick={onClose} />
      </div>
      <div className="bulk_actions_container__actions">
        {page === 'deleted' ? (
          <>
            <Button
              type="text"
              size="small"
              className="action"
              disabled={!canBulkDelete}
              onClick={async () => {
                if (!canBulkDelete) return;
                for (const asset of selectedAssets) {
                  await deleteAssetAction(
                    {
                      assetId: asset.node.id,
                      permanent: true
                    },
                    asset.node.path[0]?.id
                  )(dispatch);
                  dispatch(updateCampaignCounters('deleteFile'));
                  dispatch(updateAssetsWorkspaceCounters('deletePermanent'));
                }
                onClose();
              }}
            >
              <Delete /> Delete forever
            </Button>
            <Button
              type="text"
              size="small"
              className="action"
              disabled={!canBulkDelete}
              onClick={async () => {
                if (!canBulkDelete) return;
                for (const asset of selectedAssets) {
                  await restoreDeletedAssetAction({ assetId: asset.node.id })(
                    dispatch
                  );
                  dispatch(
                    updateAssetsWorkspaceCounters('restore', asset.node)
                  );
                }
                onClose();
              }}
            >
              Restore
            </Button>
          </>
        ) : (
          <>
            <Popover
              placement="rightBottom"
              title="SELECT FOLDER"
              trigger={['click']}
              overlayClassName="bulk_actions_overlay"
              open={isMovePopoverVisible}
              onOpenChange={(v) => {
                if (!canBulkMove) return;
                setIsMovePopoverVisible(v);
              }}
              content={
                <div
                  style={{
                    maxHeight: '200px',
                    overflow: 'auto',
                    marginRight: -15,
                    paddingRight: 15
                  }}
                >
                  {foldersList?.map((el) => (
                    <p
                      key={el.id}
                      onClick={async () => {
                        if (el.isPrivate) {
                          const assetsName = selectedAssets.map(
                            (el: any) => el.node.asset.versions[0].name
                          );
                          setIsMovePopoverVisible(false);
                          dispatch(
                            toggleMoveAssetToPrivateFolder({
                              assetsName,
                              folderName: el.name,
                              visible: true,
                              callback: () => {
                                for (const asset of selectedAssets) {
                                  dispatch(
                                    moveAssetToFolder(
                                      {
                                        assetId: asset.node.id,
                                        folderId: el.id
                                      },
                                      page === 'campaign',
                                      asset?.node?.path
                                    )
                                  );
                                }
                              }
                            })
                          );
                          return;
                        }
                        for (const asset of selectedAssets) {
                          dispatch(
                            moveAssetToFolder(
                              {
                                assetId: asset.node.id,
                                folderId: el.id
                              },
                              page === 'campaign',
                              asset.node.path
                            )
                          );
                        }
                        setIsMovePopoverVisible(false);
                        onClose();
                      }}
                    >
                      {el.name} {el.isPrivate && <PrivateEye />}
                    </p>
                  ))}
                </div>
              }
            >
              <Button
                type="text"
                size="small"
                className="action"
                disabled={!canBulkMove}
                onClick={() => {
                  if (!canBulkMove) return;
                  setIsMovePopoverVisible(true);
                }}
              >
                <Move className="action__icon action__icon--move" />
                Move to
              </Button>
            </Popover>
            <UpgradePlanTooltip
              text=" to a Team plan to access this feature."
              placement="left"
              isCanVisible={upgradeShare}
            >
              <Button
                type="text"
                size="small"
                className="action"
                disabled={!canBulkShare || upgradeShare}
                onClick={() => {
                  const assets = selectedAssets.map((it: any) => ({
                    asset: it.node
                  }));
                  dispatch(
                    toggleShareAssetModal({
                      visible: true,
                      assets,
                      action: 'create'
                    })
                  );
                  onClose();
                }}
              >
                {upgradeShare && <UpgradePlanSvg className="action__icon" />}
                {!upgradeShare && <Share className="action__icon" />}
                Share
              </Button>
            </UpgradePlanTooltip>
            <UpgradePlanTooltip
              text={
                !awFeatureEnabled
                  ? ' to a paid plan to access this feature.'
                  : ' to an Enterprise plan to route multiple media assets for approval at one time.'
              }
              placement="top"
              isCanVisible={!canUseBatchApprovals || !awFeatureEnabled}
            >
              <Button
                type="text"
                size="small"
                className="action"
                disabled={
                  !canUseBatchApprovals ||
                  !canBulkApprovalWorkflow ||
                  !awFeatureEnabled
                }
                onClick={() => {
                  if (
                    !canUseBatchApprovals ||
                    !canBulkApprovalWorkflow ||
                    !awFeatureEnabled
                  )
                    return;
                  const hasApprovalWorkflows = selectedAssets.some(
                    (item) => !!item.node.asset?.versions?.[0].approvalWorkflow
                  );
                  const campaignId = selectedAssets?.[0]?.node?.campaignId;
                  if (!campaignId) return;
                  if (hasApprovalWorkflows) {
                    dispatch(
                      toggleApprovalConflictAssetsModal({
                        visible: true,
                        selectedAssets: selectedAssets
                          .map((item) => item.node)
                          .filter(Boolean),
                        callback: () => {
                          navigate(
                            `/campaigns/single/${campaignId}?tab=approvals`
                          );
                        }
                      })
                    );
                    return;
                  }
                  const assetIds = selectedAssets.map((item) => item.node.id);
                  const assetVersions = selectedAssets
                    .filter((item) => !!item.node.asset?.versions[0])
                    .map((item) => item.node.asset!.versions[0]);
                  const allowCreate = selectedAssets.every(
                    (item) =>
                      !!item.node.asset?.permissions.createApprovalWorkflows
                  );
                  dispatch(
                    toggleApprovalModal({
                      visible: true,
                      assetVersions,
                      campaignId,
                      allowCreate,
                      isLastVersion: true,
                      assetIds,
                      callback: (asset) => {
                        onClose();
                        if (selectedAssets.length === 1) {
                          dispatch({
                            type: AssetsActionTypes.UPDATE_ASSET,
                            payload: { asset }
                          });
                        } else {
                          navigate(
                            `/campaigns/single/${campaignId}?tab=approvals`
                          );
                        }
                      }
                    })
                  );
                }}
              >
                <AWDefaultSvg
                  className="action__icon"
                  style={{ color: 'transparent' }}
                />
                Create approval routing
              </Button>
            </UpgradePlanTooltip>

            <Button
              type="text"
              size="small"
              className="action"
              disabled={!canBulkDownload}
              onClick={() => {
                if (!canBulkDownload) return;
                downloadAll(
                  selectedAssets.map(
                    (x: any) => x.node.asset.versions[0].sourceUrl
                  )
                );
                onClose();
              }}
            >
              <Download className="action__icon action__icon" />
              Download
            </Button>
            <Button
              type="text"
              size="small"
              className="action"
              disabled={!canBulkDelete}
              onClick={async () => {
                if (!canBulkDelete) return;
                for (const asset of selectedAssets) {
                  await deleteAssetAction(
                    {
                      assetId: asset.node.id,
                      permanent: false
                    },
                    asset.node.path[0]?.id
                  )(dispatch);
                  dispatch(updateCampaignCounters('deleteFile'));
                  dispatch(updateAssetsWorkspaceCounters('delete', asset.node));
                }
                onClose();
              }}
            >
              <Delete className="action__icon" />
              Delete
            </Button>
          </>
        )}
      </div>
    </div>
  );
}

export default BulkActions;
