import { AWStageMemberDto } from '@api/Api';
import CommentsCell from '@pages/Campaigns/CampaignItem/components/ApprovalsTab/components/StageReviewersTable/components/CommentsCell';
import DecisionCell from '@pages/Campaigns/CampaignItem/components/ApprovalsTab/components/StageReviewersTable/components/DecisionCell';
import NameCell from '@pages/Campaigns/CampaignItem/components/ApprovalsTab/components/StageReviewersTable/components/NameCell';
import ProgressCell from '@pages/Campaigns/CampaignItem/components/ApprovalsTab/components/StageReviewersTable/components/ProgressCell';
import ActionsCell from '@pages/Campaigns/CampaignItem/components/ApprovalsTab/components/StageReviewersTable/components/ActionsCell';
import { Table } from 'antd';
import React, { memo, useMemo } from 'react';

import './StageReviewersTable.less';

type StageReviewersTableProps = {
  members: AWStageMemberDto[];
  stageDeadline?: string | null | undefined;
  assetId: string;
  assetVersionId: string;
  stageId: string;
  currentStageId?: string;
  reviewLink: string;
};
export default memo(function StageReviewersTable({
  members,
  stageDeadline,
  assetId,
  assetVersionId,
  stageId,
  currentStageId,
  reviewLink
}: StageReviewersTableProps) {
  const columns = useMemo(
    () => [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        width: 120,
        render: (_: any, member: AWStageMemberDto) => (
          <NameCell member={member} />
        )
      },
      {
        title: 'Decisions',
        dataIndex: 'decisions',
        key: 'decisions',
        width: 160,
        render: (_: any, member: AWStageMemberDto) => (
          <DecisionCell stageDeadline={stageDeadline} member={member} />
        )
      },
      {
        title: 'Progress',
        dataIndex: 'progress',
        key: 'progress',
        width: 500,
        render: (_: any, member: AWStageMemberDto) => (
          <ProgressCell member={member} />
        )
      },
      {
        title: 'Decisions',
        dataIndex: 'decisions',
        key: 'decisions',
        width: 20,
        render: (_: any, member: AWStageMemberDto) => (
          <CommentsCell commentsCount={member.commentsCount} />
        )
      },
      {
        title: 'Actions',
        dataIndex: 'actions',
        key: 'actions',
        width: 32,
        render: (_: any, member: AWStageMemberDto) => (
          <ActionsCell
            member={member}
            assetId={assetId}
            assetVersionId={assetVersionId}
            stageId={stageId}
            currentStageId={currentStageId}
            reviewLink={reviewLink}
          />
        )
      }
    ],
    [stageDeadline]
  );
  return (
    <Table
      className="stage-reviewers-table"
      columns={columns}
      dataSource={members}
      rowKey={({ email }) => email}
      showHeader={false}
    />
  );
});
