import React, { memo } from 'react';
import { Tooltip } from 'antd';
import { AWStageMemberDto } from '@api/Api';
import { ReactComponent as SendSvg } from '@assets/icons/send-v2.svg';
import { ReactComponent as EyeSvg } from '@assets/icons/open-eye-v2.svg';
import { ReactComponent as SpinSvg } from '@assets/icons/spin.svg';
import { ReactComponent as DoneSvg } from '@assets/icons/done.svg';
import classNames from 'classnames';

type ProgressCellProps = {
  member: AWStageMemberDto;
  color?: 'white' | 'gray';
};
export default memo(function ProgressCell({
  member,
  color = 'gray'
}: ProgressCellProps) {
  const hasDecision = !!member.decision;
  const inReview = !!member.lastViewDate;
  return (
    <div className={classNames(['progress-cell', `progress-cell--${color}`])}>
      <Tooltip
        title="Link sent"
        placement="top"
        overlayClassName="toggle_favorite_overlay toggle_favorite_overlay--small"
      >
        <SendSvg className="b-active" />
      </Tooltip>
      <Tooltip
        title={inReview && 'Link opened'}
        placement="top"
        overlayClassName="toggle_favorite_overlay toggle_favorite_overlay--small"
      >
        <EyeSvg className={classNames({ 'b-active': inReview })} />
      </Tooltip>
      <Tooltip
        title={inReview && 'In review'}
        placement="top"
        overlayClassName="toggle_favorite_overlay toggle_favorite_overlay--small"
      >
        <SpinSvg className={classNames({ 'b-active': inReview })} />
      </Tooltip>
      <Tooltip
        title={!!member.decision && 'Decision submitted'}
        placement="top"
        overlayClassName="toggle_favorite_overlay toggle_favorite_overlay--small"
      >
        <DoneSvg className={classNames({ 'b-active': hasDecision })} />
      </Tooltip>
    </div>
  );
});
