import React from 'react';
import { Modal, Button } from 'antd';

import { ReactComponent as CrossSvg } from '@assets/icons/cross.svg';
import { useDispatch } from 'react-redux';
import { toggleMoveAssetToPrivateFolder } from '@redux/actions/modalAction';
import { useTypedSelector } from '@hooks';

function MoveAssetToPrivateFolderModal() {
  const modalState = useTypedSelector(
    ({ modal }) => modal.moveAssetToPrivateFolderModal
  );
  const dispatch = useDispatch();
  return (
    <Modal
      visible={modalState?.visible}
      footer={null}
      destroyOnClose
      centered={true}
      closeIcon={<CrossSvg />}
      width={500}
      onCancel={() => dispatch(toggleMoveAssetToPrivateFolder(null))}
    >
      <div className="modal_container">
        <h2
          className="modal_container__title"
          style={{ fontSize: 22, marginBottom: '0.5em' }}
        >
          Change who has access?
        </h2>
        <p className="modal_container__subtitle">
          By moving{' '}
          <strong style={{ color: '#161819' }}>
            {modalState && modalState.assetsName.length > 1
              ? `media (${modalState.assetsName.length})`
              : modalState?.assetsName[0]}
          </strong>{' '}
          to the private folder{' '}
          <strong style={{ color: '#161819' }}>{modalState?.folderName}</strong>{' '}
          , everyone who has access to{' '}
          <strong style={{ color: '#161819' }}>
            {modalState && modalState.assetsName.length > 1
              ? `media (${modalState.assetsName.length})`
              : modalState?.assetsName[0]}
          </strong>{' '}
          will lose access, unless this media is directly shared with them.{' '}
        </p>
        <div className="modal_container__actions">
          <Button
            type="text"
            size="large"
            onClick={() => dispatch(toggleMoveAssetToPrivateFolder(null))}
            style={{
              marginRight: '29px',
              height: 48,
              padding: '8px 19px'
            }}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            size="large"
            onClick={() => {
              modalState?.callback();
              dispatch(toggleMoveAssetToPrivateFolder(null));
            }}
            style={{ width: 147, height: 48, padding: '8px 15px' }}
          >
            Move
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default MoveAssetToPrivateFolderModal;
