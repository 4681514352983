import { Collapse, Tooltip } from 'antd';
import { ReactComponent as ArrowSvg } from '@assets/icons/arrow-down.svg';
import React, { memo, useCallback } from 'react';

import './ApprovalsCollapse.less';
import { ApprovalWorkflowItemDto } from '@api/Api';
import StageReviewersTable from '@pages/Campaigns/CampaignItem/components/ApprovalsTab/components/StageReviewersTable/StageReviewersTable';
import DueDateTag from '@pages/Campaigns/CampaignItem/components/ApprovalsTab/components/DueDateTag/DueDateTag';

type ApprovalsCollapseProps = {
  approval: ApprovalWorkflowItemDto;
  assetId: string;
  assetVersionId: string;
};

export default memo(function ApprovalsCollapse({
  approval,
  assetId,
  assetVersionId
}: ApprovalsCollapseProps) {
  const expandIcon = useCallback(
    ({ isActive }) => (
      <ArrowSvg style={isActive ? { transform: 'rotate(180deg)' } : {}} />
    ),
    []
  );
  return (
    <Collapse
      defaultActiveKey={['0']}
      className="approvals-collapse"
      expandIcon={expandIcon}
    >
      {approval.stages.map((stage) => {
        return (
          <Collapse.Panel
            key={stage.id}
            header={
              <div className="ant-collapse-header__copy">
                <span className="stage">
                  {stage.name}
                  {approval.currentStageId === stage.id && (
                    <span className="stage-active">Active</span>
                  )}
                </span>

                <Tooltip
                  title="Stage due date"
                  trigger="hover"
                  placement="bottom"
                  overlayClassName="task-group-settings-hint"
                >
                  <div onClick={(e) => e.stopPropagation()}>
                    {!!stage.deadline && (
                      <DueDateTag deadline={stage.deadline} />
                    )}
                  </div>
                </Tooltip>
              </div>
            }
          >
            <StageReviewersTable
              stageDeadline={stage.deadline}
              members={stage.members}
              stageId={stage.id}
              currentStageId={approval.currentStageId || undefined}
              assetId={assetId}
              assetVersionId={assetVersionId}
              reviewLink={approval.url}
            />
          </Collapse.Panel>
        );
      })}
    </Collapse>
  );
});
