import { AWStageMemberDto } from '@api/Api';
import UserAvatar from '@components/UserAvatar';
import React, { memo } from 'react';

type NameCellProps = {
  member: AWStageMemberDto;
};
export default memo(function NameCell({ member }: NameCellProps) {
  return (
    <div className="user">
      <UserAvatar
        isActive={true}
        size={36}
        src={member.picture.url}
        userEmail={member.email}
        userName={member.name || ''}
      />
      <div className="user-info">
        <div className="name">{member.name}</div>
        <div className="email">{member.email}</div>
      </div>
    </div>
  );
});
