import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useMixpanel } from 'react-mixpanel-browser';
import {
  Modal,
  Button,
  Form,
  Tabs,
  Input,
  Dropdown,
  Menu,
  Row,
  Col,
  message,
  Divider,
  Radio,
  Select,
  Tooltip
} from 'antd';
import {
  generateWorkspacePublicInvitationUrl,
  findWorkspaceMembersToInvite,
  inviteWorkspaceMembers,
  deleteWorkspacePublicInvitationUrl,
  workspaceListMemberGroups
} from '@api/Workspace';
import { ReactComponent as CrossSvg } from '@assets/icons/cross.svg';
import { ReactComponent as CrossSvgSm } from '@assets/icons/cross-sm.svg';
import { ReactComponent as LinkSvg } from '@assets/icons/link.svg';
import { ReactComponent as CopySvg } from '@assets/icons/copy.svg';
import { ReactComponent as PlusSvg } from '@assets/icons/plus.svg';
import { ReactComponent as ArrowDownSvg } from '@assets/icons/arrow-down.svg';
import { ReactComponent as SuccessSvg } from '@assets/icons/success.svg';
import { ReactComponent as PublicSvg } from '@assets/icons/public.svg';
import classNames from 'classnames';
import { useTypedSelector } from '@hooks';
import {
  toggleReachedEntityLimitModal,
  toggleWorkspaceInvitationModal
} from '@redux/actions/modalAction';
import ImageComponent from '@components/ImageComponent';
import { toast } from 'react-toastify';
import { ActionToast } from '@components/Toasts';
import { useAuth } from '@hooks/useAuth';
import { MixpanelEventType, MixpanelService } from '@services/mixpanelService';
import { nonNull } from '@helpers/non-null';
import { organizationItemStateSelector } from '@redux/selectors/organizations';
import store from '@redux/store';
import { IOrganizationItemState } from '@redux/types/organizations';
import { updateWorkspace } from '@redux/actions/workspaceAction';
import InviteMembersSelect, {
  SelectedItem
} from '@components/InviteMembersSelect/InviteMembersSelect';
import {
  useInfiniteQuery,
  useMutation,
  useQueryClient
} from '@tanstack/react-query';
import { WorkspaceRole } from '@helpers/userRoles';
import Icon from '@ant-design/icons';
import { ReactComponent as UpgradePlanSvg } from '@assets/icons/diamond-upgrade.svg';
import UpgradePlanTooltip from '@components/Tooltip/UpgradePlanTooltip';
import { useDebounced } from '@helpers/useDebounced';
import { apiClient } from '@api/client';

const { TabPane } = Tabs;

type userRoleCopyProps = {
  [id: string]: string;
};

export const userRoleCopy: userRoleCopyProps = {
  'workspace://team-lead': 'Manager',
  'workspace://member': 'Member',
  'workspace://contributor': 'Contributor'
};

function InviteToWorkspaceModal() {
  const dispatch = useDispatch();
  const mixpanel = useMixpanel();
  const workspaceInvitationModal = useTypedSelector(
    ({ modal }) => modal.workspaceInvitationModal
  );
  const { user } = useAuth(true, 'internal');
  const [isLinkCopied, setIsLinkCopied] = useState<boolean>(false);
  const [accessLink, setAccessLink] = useState<string>('');
  const [isLinkDisabled, setIsLinkDisabled] = useState<boolean>(true);
  const [role, setRole] = useState<WorkspaceRole>('workspace://member');
  const [customMessageIsOpen, setCustomMessageIsOpen] =
    useState<boolean>(false);
  const [currentOrganization, setCurrentOrganization] =
    useState<IOrganizationItemState>();

  const [form] = Form.useForm();

  useEffect(() => {
    setIsLinkCopied(false);
    if (workspaceInvitationModal?.visible) {
      const currentOrganization = organizationItemStateSelector(
        store.getState(),
        {
          organizationId:
            workspaceInvitationModal?.workspace?.organizationId || ''
        }
      );

      if (!currentOrganization) return;
      setCurrentOrganization(currentOrganization);

      setIsLinkDisabled(
        workspaceInvitationModal.workspace?.publicInvitationUrl === null
      );
      setAccessLink(
        workspaceInvitationModal.workspace?.publicInvitationUrl || ''
      );
    }
  }, [workspaceInvitationModal, user]);

  useEffect(() => {
    setCustomMessageIsOpen(false);
    setRole('workspace://member');
    form.resetFields();
  }, [form, workspaceInvitationModal]);

  const queryClient = useQueryClient();
  const inviteMutation = useMutation({
    mutationFn: async ({
      userList,
      message,
      groupType,
      groupId,
      groupName
    }: {
      userList: SelectedItem[];
      message: string;
      groupType: 'none' | 'existing' | 'new';
      groupId?: string;
      groupName?: string;
    }) => {
      const workspaceId = nonNull(workspaceInvitationModal?.workspace?.id);
      const users = userList
        .filter((x) => x.type === 'new' || x.type === 'member')
        .map((x) => ({
          memberIdOrEmail: x.type === 'new' ? x.email : x.id,
          role,
          customMessage: message,
          groupId
        }));
      if (groupType === 'new') {
        await apiClient.workspace.workspaceControllerCreateWorkspaceMemberGroup(
          {
            workspaceId,
            name: nonNull(groupName),
            users
          }
        );
      } else {
        await inviteWorkspaceMembers(workspaceId, users);
      }
    },
    onSuccess: (_, { userList, groupType, groupId, groupName }) => {
      let message = `${
        userList.length > 1 ? 'Users were' : 'User was'
      } invited to workspace`;
      if (groupType !== 'none' && (groupId || groupName)) {
        message += ` and group`;
      }
      toast(
        <ActionToast
          title={message}
          onUndo={undefined}
          description={undefined}
          closeToast={undefined}
        />,
        {
          hideProgressBar: true,
          style: { width: '345px', height: 80 },
          bodyClassName: 'toast_container--invitation'
        }
      );
      MixpanelService.track(
        mixpanel,
        user.id,
        MixpanelEventType.MANAGE_WORKSPACES,
        {
          newWorkspaceName: workspaceInvitationModal?.workspace?.name ?? '',
          addMember: true,
          accessLink: false,
          deleteWorkspace: false,
          updateWorkspace: false,
          oldWorkspaceName: null,
          uploadProfilePhoto: false,
          planType: currentOrganization?.entity?.planType ?? 'free'
        }
      );
      dispatch(toggleWorkspaceInvitationModal(null));
    },
    onError: (e: any) => {
      if (e?.response?.data?.errorCode === 'subscription_users_limit_error') {
        dispatch(
          toggleReachedEntityLimitModal({
            visible: true,
            entity: 'user'
          })
        );
        dispatch(toggleWorkspaceInvitationModal(null));
      }
      if (e.response?.data?.errorCode === 'member_group_already_exists') {
        form.setFields([
          {
            name: 'groupName',
            errors: ['Group with this name already exists']
          }
        ]);
      }
    },
    onSettled: async () => {
      await Promise.all([
        queryClient.invalidateQueries(['workspace', 'members']),
        queryClient.invalidateQueries(['workspace', 'member-groups'])
      ]);
    }
  });

  const copyInviteLink = () => {
    navigator.clipboard.writeText(accessLink);
    setIsLinkCopied(true);
    message.success({
      content: 'Copied link to clipboard',
      className: 'message-dark-modal',
      icon: <SuccessSvg className="anticon" />
    });
  };

  const onGenerateWorkspacePublicInvitationUrl = async () => {
    const subdomain = currentOrganization?.entity?.subdomain || '';
    const workspace = nonNull(workspaceInvitationModal?.workspace);
    const {
      data: { publicInvitationUrl }
    } = await generateWorkspacePublicInvitationUrl(subdomain, workspace.id);
    setAccessLink(publicInvitationUrl);
    dispatch(updateWorkspace({ ...workspace, publicInvitationUrl }));
    MixpanelService.track(
      mixpanel,
      user.id,
      MixpanelEventType.MANAGE_WORKSPACES,
      {
        newWorkspaceName: workspace.name,
        accessLink: true,
        deleteWorkspace: false,
        updateWorkspace: false,
        oldWorkspaceName: null,
        uploadProfilePhoto: false,
        planType: currentOrganization?.entity?.planType ?? 'free',
        addMember: false
      }
    );
  };

  const onDeleteWorkspacePublicInvitationUrl = async () => {
    const workspace = nonNull(workspaceInvitationModal?.workspace);
    await deleteWorkspacePublicInvitationUrl({
      workspaceId: workspace.id
    });
    setAccessLink('');
    dispatch(updateWorkspace({ ...workspace, publicInvitationUrl: null }));
  };

  const needUpgradeUserGroups =
    !currentOrganization?.entity?.features.userGroups;
  const userList = Form.useWatch(['userList'], form) as
    | SelectedItem[]
    | undefined;
  const groupType = Form.useWatch(['groupType'], form) as
    | 'none'
    | 'existing'
    | 'new'
    | undefined;
  const [groupSearchQuery, setGroupSearchQuery] = useState<string>('');
  const debouncedGroupSearchQuery = useDebounced(groupSearchQuery, 300);
  const memberGroupsQuery = useInfiniteQuery({
    queryKey: [
      'workspace',
      'member-groups',
      'list',
      workspaceInvitationModal?.workspace?.id,
      debouncedGroupSearchQuery
    ],
    keepPreviousData: true,
    enabled: !!workspaceInvitationModal?.visible,
    queryFn: async ({ pageParam, signal }) => {
      const { data } = await workspaceListMemberGroups(
        {
          workspaceId: nonNull(workspaceInvitationModal?.workspace?.id),
          searchQuery: debouncedGroupSearchQuery,
          after: pageParam
        },
        { signal }
      );
      return data;
    },
    getNextPageParam: (lastPage) =>
      lastPage.hasNext ? lastPage.endCursor : undefined
  });
  const memberGroupsList = useMemo(
    () =>
      memberGroupsQuery.data?.pages.flatMap((x) =>
        x.edges.map((x) => x.node)
      ) ?? [],
    [memberGroupsQuery.data]
  );

  return (
    <Modal
      open={workspaceInvitationModal?.visible}
      footer={null}
      destroyOnClose
      centered={true}
      closeIcon={<CrossSvg />}
      width={600}
      onCancel={() => {
        dispatch(toggleWorkspaceInvitationModal(null));
      }}
      zIndex={1005}
    >
      <div className="modal_container">
        <h2 className="modal_container__title" style={{ fontSize: '22px' }}>
          Invite to workspace
        </h2>
        <div className="modal_container__entity_info">
          <div className="workspace_info__image">
            {workspaceInvitationModal?.workspace && (
              <ImageComponent
                alt={`${workspaceInvitationModal.workspace.name} avatar`}
                src={workspaceInvitationModal.workspace.picture.url}
                width={40}
                height={40}
                types={['png', 'webp']}
                id={workspaceInvitationModal.workspace.id}
                name={workspaceInvitationModal.workspace.name}
              />
            )}
          </div>
          <span>{workspaceInvitationModal?.workspace?.name}</span>
        </div>
        <Tabs
          defaultActiveKey="email_or_username"
          className="modal_container__invitation_type"
        >
          <TabPane tab="Email or username" key="email_or_username">
            <Form
              form={form}
              layout="vertical"
              onFinish={inviteMutation.mutate}
              initialValues={{
                userList: [],
                message: '',
                groupType: 'none'
              }}
              requiredMark={false}
            >
              <Form.Item
                label="Invite user"
                style={{ position: 'relative', marginBottom: 16 }}
              >
                <Form.Item name="userList" noStyle>
                  <InviteMembersSelect
                    key={workspaceInvitationModal?.workspace?.id}
                    queryEnabled={workspaceInvitationModal?.visible}
                    membersQueryKey={[
                      'workspace',
                      'members',
                      'find-to-invite',
                      workspaceInvitationModal?.workspace?.id
                    ]}
                    membersQueryFn={async ({
                      pageParam,
                      searchQuery,
                      signal
                    }) => {
                      const { data } = await findWorkspaceMembersToInvite(
                        {
                          targetId: nonNull(
                            workspaceInvitationModal?.workspace?.id
                          ),
                          after: pageParam,
                          searchQuery
                        },
                        { signal }
                      );
                      return data;
                    }}
                  />
                </Form.Item>
                <Dropdown
                  className="select_role"
                  overlayClassName="select_role_dropdown"
                  overlay={
                    <Menu
                      onClick={(e) => setRole(e.key as any)}
                      selectedKeys={[role || '']}
                    >
                      <Menu.Item
                        disabled={
                          !workspaceInvitationModal?.workspace?.permissions
                            .inviteTeamLead
                        }
                        key="workspace://team-lead"
                      >
                        <p className="title">Workspace lead</p>
                        <p className="description">Manages all campaigns</p>
                      </Menu.Item>
                      <Menu.Item
                        disabled={
                          !workspaceInvitationModal?.workspace?.permissions
                            .inviteMember
                        }
                        key="workspace://member"
                      >
                        <p className="title">Member</p>
                        <p className="description">Can access campaigns</p>
                      </Menu.Item>
                      <Menu.Item
                        disabled={
                          !workspaceInvitationModal?.workspace?.permissions
                            .inviteContributor
                        }
                        key="workspace://contributor"
                      >
                        <p className="title">Contributor</p>
                        <p className="description">
                          Can access specific campaigns
                        </p>
                      </Menu.Item>
                    </Menu>
                  }
                  placement="bottomRight"
                >
                  <div>{role ? userRoleCopy[role] : 'Select role'}</div>
                </Dropdown>
              </Form.Item>
              <Button
                className="modal_container__custom_message"
                type="link"
                onClick={() => setCustomMessageIsOpen((value) => !value)}
              >
                <PlusSvg /> Add custom message
              </Button>
              {customMessageIsOpen && (
                <Form.Item
                  name="message"
                  style={{ position: 'relative' }}
                  noStyle
                >
                  <Input.TextArea
                    rows={4}
                    placeholder="Type your message here..."
                    style={{ resize: 'none', padding: 12, marginTop: 8 }}
                  />
                </Form.Item>
              )}
              <Divider />
              <Form.Item name="groupType" label="Invite users to group">
                <Radio.Group>
                  <Radio value="none">None</Radio>
                  <Tooltip
                    placement="top"
                    overlayClassName="toggle_favorite_overlay"
                    title={
                      !memberGroupsList.length && !memberGroupsQuery.isLoading
                        ? 'You have no existing groups. Choose "Create new group" to create a new one.'
                        : null
                    }
                  >
                    <Radio
                      value="existing"
                      disabled={
                        !memberGroupsList.length && !memberGroupsQuery.isLoading
                      }
                    >
                      Existing group
                    </Radio>
                  </Tooltip>
                  <UpgradePlanTooltip
                    text=" to a Team plan to access this feature."
                    isCanVisible={needUpgradeUserGroups}
                  >
                    <Radio value="new" disabled={needUpgradeUserGroups}>
                      Create new group{' '}
                      {needUpgradeUserGroups && (
                        <Icon
                          component={UpgradePlanSvg}
                          style={{ marginLeft: 5 }}
                        />
                      )}
                    </Radio>
                  </UpgradePlanTooltip>
                </Radio.Group>
              </Form.Item>
              {groupType === 'new' && (
                <Form.Item
                  name="groupName"
                  label="Group name"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter a group name'
                    }
                  ]}
                >
                  <Input
                    size="large"
                    placeholder="e.g Marketing"
                    autoFocus
                    maxLength={255}
                  />
                </Form.Item>
              )}
              {groupType === 'existing' && (
                <Form.Item name="groupId" label="Select group">
                  <Select
                    size="large"
                    showSearch
                    placeholder="Search by name"
                    filterOption={false}
                    onSearch={setGroupSearchQuery}
                    onSelect={() => setGroupSearchQuery('')}
                    loading={memberGroupsQuery.isFetching}
                    options={memberGroupsList.map((x) => ({
                      label: x.name,
                      value: x.id
                    }))}
                    onPopupScroll={(e) => {
                      const query = memberGroupsQuery;
                      if (query.isFetchingNextPage || !query.hasNextPage)
                        return;
                      const target = e.target as HTMLDivElement;
                      if (
                        target.scrollTop + target.offsetHeight ===
                        target.scrollHeight
                      ) {
                        query.fetchNextPage();
                      }
                    }}
                  />
                </Form.Item>
              )}
              <div
                className="modal_container__actions"
                style={{ marginTop: 32 }}
              >
                <Button
                  type="text"
                  size="large"
                  style={{
                    marginRight: 16,
                    border: 'none',
                    height: 48,
                    padding: '8px 32px'
                  }}
                  onClick={() => dispatch(toggleWorkspaceInvitationModal(null))}
                >
                  Cancel
                </Button>
                <Button
                  loading={inviteMutation.isLoading}
                  disabled={!userList?.length}
                  type="primary"
                  size="large"
                  className="submit_form"
                  htmlType="submit"
                  style={{
                    width: 216,
                    height: 48,
                    padding: '8px 15px'
                  }}
                >
                  Invite to workspace
                </Button>
              </div>
            </Form>
          </TabPane>
          <TabPane
            disabled={
              !workspaceInvitationModal?.workspace?.permissions.publicInvitation
            }
            tab="Invite link"
            key="invite_link"
          >
            <p className="modal_container__copy">Link access to workspace</p>
            <Row justify="start" gutter={10} style={{ marginBottom: 12 }}>
              <Col>
                <Button
                  type="text"
                  className={classNames(
                    'modal_container__link_block',
                    isLinkDisabled && 'modal_container__link_block_disabled'
                  )}
                  onClick={() => {
                    if (isLinkDisabled) return;
                    copyInviteLink();
                    if (document.activeElement instanceof HTMLElement) {
                      document.activeElement.blur();
                    }
                  }}
                >
                  <div className="link_row">
                    <LinkSvg />
                    <span
                      className={classNames(
                        'link',
                        isLinkDisabled && 'link_disabled'
                      )}
                    >
                      {accessLink
                        ? accessLink.replace(/(^\w+:|^)\/\//, '')
                        : 'No access link'}
                    </span>
                  </div>
                  {!isLinkDisabled && (
                    <CopySvg className={classNames({ isLinkCopied })} />
                  )}
                </Button>
              </Col>
              <Col>
                <div className="modal_container__link_block modal_container__link_block__controller">
                  <Dropdown
                    className="select_link_access"
                    overlayClassName="select_role_dropdown"
                    overlay={
                      <Menu
                        selectedKeys={
                          !isLinkDisabled ? ['Public'] : ['Disabled']
                        }
                      >
                        <Menu.Item
                          key="Public"
                          onClick={async () => {
                            await onGenerateWorkspacePublicInvitationUrl();
                            setIsLinkDisabled(false);
                          }}
                        >
                          <p className="title">Public</p>
                          <p className="description">
                            Anyone with this link can access
                          </p>
                        </Menu.Item>
                        <Menu.Item
                          key="Disabled"
                          onClick={async () => {
                            await onDeleteWorkspacePublicInvitationUrl();
                            setIsLinkDisabled(true);
                          }}
                        >
                          <p className="title">Disabled</p>
                          <p className="description">
                            No one can access this link
                          </p>
                        </Menu.Item>
                      </Menu>
                    }
                    trigger={['click']}
                    placement="bottomLeft"
                  >
                    <div className="select_access">
                      <span>
                        {!isLinkDisabled ? (
                          <PublicSvg className="link_public" />
                        ) : (
                          <CrossSvgSm className="link_disable" />
                        )}

                        {!isLinkDisabled ? 'Public' : 'Disabled'}
                      </span>
                      <ArrowDownSvg className="select_arrow" />
                    </div>
                  </Dropdown>
                </div>
              </Col>
            </Row>
            <p className="modal_container__hint" style={{ margin: 0 }}>
              Share this link with anyone you&apos;d like to invite to this
              workspace.{' '}
            </p>
          </TabPane>
        </Tabs>
      </div>
    </Modal>
  );
}

export default InviteToWorkspaceModal;
