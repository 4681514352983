import { AssetForApprovalDto } from '@api/Api';
import Loader from '@components/Loader';
import { assetPreviewThumbnails } from '@helpers/assetPreviewThumbnails';
import isSomeAvStageOverdue from '@helpers/isSomeAvStageOverdue';
import DueDateTag from '@pages/Campaigns/CampaignItem/components/ApprovalsTab/components/DueDateTag/DueDateTag';
import React, { memo, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

type NameCellProps = {
  node: AssetForApprovalDto;
};

export default memo(function NameCell({ node }: NameCellProps) {
  const [isAwOverdue, setIsAwOverdue] = useState<boolean>();
  const navigate = useNavigate();
  const location = useLocation();
  const preparePreview = useMemo(() => {
    return assetPreviewThumbnails({
      url: node.asset.versions?.[0].metadata?.preview?.url || '',
      status: node.asset.versions?.[0]?.metadata?.preview?.status,
      type: node.asset.versions?.[0]?.metadata?.type,
      size: 40,
      format: 'img'
    });
  }, [node.asset.versions]);
  useEffect(() => {
    if (!node.asset.versions[0].approvalWorkflow) return;
    const { stages } = node.asset.versions[0].approvalWorkflow;
    const intervalId = setInterval(() => {
      setIsAwOverdue(isSomeAvStageOverdue(stages));
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [node.asset.versions]);

  const onOpenAsset = () => {
    navigate(`/media-viewer/${node.id}/${node.asset.versions[0].id}`, {
      state: {
        returnTo: location.pathname + location.search
      }
    });
  };

  return (
    <div className="name-cell">
      {preparePreview === 'pending' && (
        <Loader
          classNameWrapper="table-assets-loader-wrapper"
          spinSize={40}
          isSpinning={true}
        />
      )}
      {preparePreview !== 'pending' && typeof preparePreview === 'string' ? (
        <img src={preparePreview || ''} alt="" onClick={onOpenAsset} />
      ) : (
        preparePreview
      )}
      <div className="info">
        <div className="title" onClick={onOpenAsset}>
          <div className="asset-name">
            {node.asset.versions[0].name}.{node.asset.versions[0].extension}
          </div>
          <div className="asset-version">V{node.asset.versionsCount}</div>
        </div>
        {isAwOverdue && <DueDateTag simple />}
      </div>
    </div>
  );
});
