import React, { memo, useCallback, useMemo } from 'react';
import { Button, Dropdown, message } from 'antd';
import { AWStageMemberDto } from '@api/Api';
import { sendReminderMemberApprovalStage } from '@api/Approval';
import { ReactComponent as SuccessSvg } from '@assets/icons/success.svg';
import { ReactComponent as ActionSvg } from '@assets/icons/action.svg';

type ActionsCellProps = {
  member: AWStageMemberDto;
  assetId: string;
  assetVersionId: string;
  stageId: string;
  currentStageId?: string;
  reviewLink: string;
};
export default memo(function ActionsCell({
  member,
  assetId,
  assetVersionId,
  currentStageId,
  stageId,
  reviewLink
}: ActionsCellProps) {
  const onSendReminder = useCallback(async () => {
    message.success({
      content: 'Reminder has been sent!',
      className: 'message-dark-modal',
      icon: <SuccessSvg className="anticon" />
    });
    await sendReminderMemberApprovalStage({
      assetId,
      assetVersionId,
      stageId,
      emails: [member.email]
    });
  }, [member.email, assetId, stageId, assetVersionId]);

  const actions = useMemo(() => {
    const items = [];
    if (reviewLink) {
      items.push({
        key: 'approval-link',
        label: 'Copy approval link',
        onClick: () => {
          navigator.clipboard.writeText(reviewLink);
          message.success({
            content: 'Link copied',
            className: 'message-dark-modal',
            icon: <SuccessSvg className="anticon" />
          });
        }
      });
    }
    if (!member.me && currentStageId) {
      items.push({
        key: 'send-reminder',
        label: 'Send a reminder',
        onClick: () => {
          onSendReminder();
        }
      });
    }
    return items;
  }, [member, reviewLink, stageId, currentStageId, onSendReminder]);

  return (
    <Dropdown
      trigger={['click']}
      menu={{
        items: actions
      }}
      overlayClassName="entities-dropdown-menu-overlay"
    >
      <div className="dropdown-menu-button-container">
        <Button type="text" disabled={!actions.length}>
          <ActionSvg style={{ marginLeft: 0, marginRight: 0 }} />
        </Button>
      </div>
    </Dropdown>
  );
});
