import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Modal, Skeleton, Tooltip } from 'antd';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { ReactComponent as CrossSvg } from '@assets/icons/cross.svg';
import { ReactComponent as InfoSvg } from '@assets/icons/info-icon.svg';
import { useTypedSelector } from '@hooks';
import { getAssetList } from '@api/Asset';
import { AssetItemDto } from '@api/Api';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import {
  toggleApprovalConflictAssetsModal,
  toggleApprovalModal
} from '@redux/actions/modalAction';
import { useAuth } from '@hooks/useAuth';
import { ApprovalConflictAssetsModalStateProps } from '@redux/types/modalType';
import AssetCard from '@components/Modals/ApprovalConflictAssetsModal/components/AssetCard';

import './ApprovalConflictAssetsModal.less';

function ApprovalConflictAssetsModal({
  visible,
  selectedAssets,
  workspaceId,
  campaignId,
  title,
  parentId,
  callback
}: ApprovalConflictAssetsModalStateProps) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(!(selectedAssets || []).length);
  const [localAssets, setLocalAssets] = useState(selectedAssets || []);

  useEffect(() => {
    if (!visible) return;
    if (!workspaceId) return;
    if (selectedAssets?.length) return;
    const fetchAllData = async () => {
      let allData: AssetItemDto[] = [];
      let hasNext = true;
      let endCursor = null;

      setIsLoading(true);

      while (hasNext) {
        try {
          const { data: newData } = await getAssetList({
            workspaceId,
            after: endCursor,
            campaignId,
            parentId,
            flatten: true,
            isFolder: false,
            limit: 50
          });

          allData = [...allData, ...newData.edges.map((item) => item.node)];
          hasNext = newData.hasNext;
          endCursor = newData.endCursor;
        } catch (error) {
          console.error('Error fetching data:', error);
          break;
        }
      }
      setLocalAssets(allData);
      setIsLoading(false);
    };

    fetchAllData();
  }, [visible, selectedAssets]);

  const assetsWithWorkflow = useMemo(() => {
    if (!localAssets.length) return [];
    return localAssets.filter(
      (item) => !!item?.asset?.versions?.[0]?.approvalWorkflow
    );
  }, [localAssets]);

  const onCloseModal = useCallback(
    () => dispatch(toggleApprovalConflictAssetsModal(null)),
    [dispatch]
  );

  const hasAllAssetsWithAW = useMemo(() => {
    return localAssets.every(
      (item) => !!item?.asset?.versions?.[0]?.approvalWorkflow
    );
  }, [localAssets]);

  const onCreateApproval = useCallback(() => {
    const assets = localAssets.filter(
      (item) => !item?.asset?.versions?.[0]?.approvalWorkflow
    );
    const assetIds = assets.map((item) => item.id);
    const assetVersions = assets
      .filter((item) => !!item.asset?.versions[0])
      .map((item) => item.asset!.versions[0]);
    const allowCreate = assets.every(
      (item) => !!item.asset?.permissions.createApprovalWorkflows
    );
    onCloseModal();
    dispatch(
      toggleApprovalModal({
        visible: true,
        assetVersions,
        campaignId: assets[0].campaignId,
        allowCreate,
        isLastVersion: true,
        title,
        assetIds,
        callback: () => {
          callback?.();
        }
      })
    );
  }, [localAssets, title, onCloseModal, dispatch]);

  useEffect(() => {
    if (!isLoading && !assetsWithWorkflow.length) onCreateApproval();
  }, [isLoading, assetsWithWorkflow, onCreateApproval]);

  return (
    <Modal
      open={visible}
      footer={null}
      centered={true}
      width={800}
      destroyOnClose
      zIndex={1008}
      onCancel={onCloseModal}
      className={classNames('approval-conflict-assets')}
      afterClose={() => dispatch(toggleApprovalModal(null))}
    >
      {isLoading && !assetsWithWorkflow.length && (
        <Skeleton.Input block active style={{ height: '50vh' }} />
      )}
      {!isLoading && !!assetsWithWorkflow.length && (
        <>
          <CrossSvg className="close-task-modal" onClick={onCloseModal} />
          <div className="approval-conflict-assets_wrapper">
            <div className="approval-conflict-assets_title">
              {title || 'Approval routing'}
            </div>
            <div className="approval-conflict-assets_info">
              <InfoSvg className="approval-conflict-assets_info-icon" />
              <div className="approval-conflict-assets_info-text">
                These assets are in an active approval routing. To add them to a
                new approval routing, you&apos;ll need to first remove them from
                the current one.
              </div>
            </div>
            <OverlayScrollbarsComponent className="approval-conflict-assets_content">
              <div className="approval-conflict-assets_content-inner">
                {assetsWithWorkflow.map((item) => (
                  <div key={item.id} className="approval-conflict-assets_item">
                    <AssetCard asset={item} />
                  </div>
                ))}
              </div>
            </OverlayScrollbarsComponent>
            <div className="approval-conflict-assets_bottom">
              <Button type="text" onClick={onCloseModal}>
                Cancel
              </Button>
              <Tooltip
                title="All assets are currently in an active approval workflow"
                overlayClassName="toggle_favorite_overlay"
                trigger={hasAllAssetsWithAW ? ['hover', 'click'] : []}
              >
                <Button
                  type="primary"
                  disabled={hasAllAssetsWithAW}
                  onClick={onCreateApproval}
                >
                  Skip & Create approval routing
                </Button>
              </Tooltip>
            </div>
          </div>
        </>
      )}
    </Modal>
  );
}

export default function ApprovalConflictAssetsModalContainer() {
  const { user } = useAuth();
  const modalState = useTypedSelector(
    ({ modal }) => modal.approvalConflictAssetsModal
  );
  if (!modalState || !user) return null;
  return <ApprovalConflictAssetsModal {...modalState} />;
}
