import React from 'react';
import { Button, Divider, Tooltip } from 'antd';
import { ReactComponent as LinkSvg } from '@assets/icons/link.svg';
import { ReactComponent as CopySvg } from '@assets/icons/copy.svg';
import { ReactComponent as WarningSvg } from '@assets/icons/warning.svg';
import { AssetItemDto } from '@api/Api';
import {
  toggleApprovalModal,
  toggleShareAssetModal
} from '@redux/actions/modalAction';
import { updateAssetSelectedVersion } from '@redux/actions/mediaViewerAction';
import useTypedDispatch from '@hooks/useTypedDispatch';

type SingleAssetProps = {
  asset: {
    asset: AssetItemDto;
    versionId?: string | undefined;
  }[];
  totalAssetsCount: number;
};
type ManyAssetsProps = {
  assets: {
    asset: AssetItemDto;
    versionId?: string | undefined;
  }[];
};

function ApprovalWorkflow({
  assets,
  assetWithActiveAw
}: {
  assets:
    | {
        asset: AssetItemDto;
        versionId?: string | undefined;
      }[]
    | undefined;
  assetWithActiveAw: {
    asset: AssetItemDto;
    versionId?: string | undefined;
  }[];
}) {
  const isMoreThanOneAssetWithAW = (assetWithActiveAw?.length || 0) > 1;
  const totalAssetsCount = assets?.length || 0;
  return (
    <div className="share_asset_modal_approval">
      <div className="share_asset_modal_info">
        <p className="modal_container__copy">Approval routing in progress</p>
        {isMoreThanOneAssetWithAW ? (
          <ManyAssets assets={assetWithActiveAw} />
        ) : (
          <SingleAsset
            totalAssetsCount={totalAssetsCount}
            asset={assetWithActiveAw}
          />
        )}
      </div>
      <Divider className="modal_container_divider" />
      <p
        className="modal_container__copy"
        style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
      >
        <WarningSvg /> <span>Media link</span>
      </p>
      <Tooltip
        placement="topRight"
        overlayClassName="toggle_favorite_overlay"
        title="Link disabled while media in approval routing"
        overlayInnerStyle={{ width: '280px' }}
      >
        <div className="modal_container__link_block modal_container__link_block_forbidden">
          <div className="link_row">
            <LinkSvg />
            <span className="link">
              {
                assetWithActiveAw[0]?.asset.asset?.versions[0].approvalWorkflow
                  ?.url
              }
            </span>
          </div>
          <CopySvg className="isLinkCopied" />
        </div>
      </Tooltip>
    </div>
  );
}

function ManyAssets({ assets }: ManyAssetsProps) {
  return (
    <div className="share_asset_modal_info-text">
      The media has already been routed for approval and cannot be shared. To
      share the{' '}
      {assets.map((it, index) => {
        const isLast = assets.length - 1 === index;
        const v =
          it.asset.asset?.versions.find((x) => x.id === it.versionId) ??
          it.asset.asset?.versions[0];
        if (!v) return;
        return (
          <span style={{ color: '#161819', fontWeight: 'bold' }} key={v.id}>
            {v.name}.{v.extension}
            {!isLast && ', '}
          </span>
        );
      })}{' '}
      media, please remove it from approval routing.
    </div>
  );
}

function SingleAsset({ totalAssetsCount, asset }: SingleAssetProps) {
  const dispatch = useTypedDispatch();

  const version = asset[0].asset.asset?.versions[0];
  return (
    <div className="share_asset_modal_info-text">
      {totalAssetsCount > 1 ? (
        <>
          The{' '}
          <span style={{ color: '#161819', fontWeight: 'bold' }}>
            {version?.name}.{version?.extension}
          </span>{' '}
          asset can&apos;t be shared while approvals are in progress.
        </>
      ) : (
        <>This asset can&apos;t be shared while approvals are in progress.</>
      )}
      <Button
        type="link"
        style={{ padding: 0, height: 19 }}
        onClick={() => {
          if (!version) return;
          dispatch(toggleShareAssetModal(null));
          dispatch(
            toggleApprovalModal({
              visible: true,
              assetVersions: [version],
              isLastVersion: true,
              assetIds: [asset[0].asset.id],
              campaignId: asset[0].asset.campaignId,
              allowCreate:
                !!asset[0].asset.asset?.permissions.createApprovalWorkflows,
              callback: (asset) => {
                const variant = asset.asset?.versions.find(
                  (el) => el.id === version?.id
                );
                if (!variant) return;
                dispatch(
                  updateAssetSelectedVersion({
                    newSelectedVersion: variant
                  })
                );
              }
            })
          );
        }}
      >
        View current status of the approval routing
      </Button>
      .
    </div>
  );
}

export default ApprovalWorkflow;
