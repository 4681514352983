import { useTypedSelector } from '@hooks';
import useTypedDispatch from '@hooks/useTypedDispatch';
import { addAssetToStackAction } from '@redux/actions/assetsAction';
import {
  toggleAssetStackErrorModal,
  toggleCombineAssetsModal
} from '@redux/actions/modalAction';
import { updateAssetsWorkspaceCounters } from '@redux/actions/workspaceCountersAction';
import { useCallback, useRef } from 'react';

export function useCombineAssets() {
  const dispatch = useTypedDispatch();
  const assetsList = useTypedSelector(({ assets }) => assets.files.edges);
  const assetsListRef = useRef(assetsList);
  assetsListRef.current = assetsList;
  return useCallback(
    (assetId: string, toAssetId: string) => {
      if (assetId === toAssetId) return;
      const draggedAsset = assetsListRef.current
        ?.map((x) => x.node)
        .find((el) => el.id === assetId);
      if (!draggedAsset?.asset) return;
      const toAsset = assetsListRef.current
        ?.map((x) => x.node)
        .find((el) => el.id === toAssetId);
      if (!toAsset?.asset) return;
      const { permissions } = toAsset.asset;

      if (!permissions.uploadNewVersion) {
        dispatch(
          toggleAssetStackErrorModal({
            visible: true,
            copy: 'You have no permission to upload new media version'
          })
        );
        return;
      }

      const isAssetHasAnyAW = draggedAsset.asset.versions[0].approvalWorkflow;
      if (draggedAsset.asset.versionsCount !== 1 || isAssetHasAnyAW) {
        dispatch(
          toggleAssetStackErrorModal({
            visible: true,
            copy: 'You cannot merge media that already have versions or approval workflow.'
          })
        );
        return;
      }
      const aw = toAsset.asset?.versions[0].approvalWorkflow;
      const isAssetWithAW = !!aw && !aw.isPaused && !aw.isFinished;
      if (isAssetWithAW) {
        const toVersion = toAsset.asset.versions[0];
        dispatch(
          toggleCombineAssetsModal({
            visible: true,
            toAssetId,
            toAssetName: `${toVersion.name}.${toVersion.extension}`,
            assetId
          })
        );
        return;
      }
      dispatch(addAssetToStackAction({ assetId, toAssetId }));
      dispatch(updateAssetsWorkspaceCounters('addToStack', draggedAsset));
    },
    [dispatch]
  );
}
