import React, { useState } from 'react';
import {
  Form,
  Row,
  Col,
  Button,
  Divider,
  message,
  Checkbox,
  Input
} from 'antd';
import { ReactComponent as LinkSvg } from '@assets/icons/link.svg';
import { ReactComponent as CopySvg } from '@assets/icons/copy.svg';
import { ReactComponent as SuccessSvg } from '@assets/icons/success.svg';
import InviteMembersSelect, {
  SelectedItem
} from '@components/InviteMembersSelect/InviteMembersSelect';
import { nonNull } from '@helpers/non-null';
import { useMutation } from '@tanstack/react-query';
import { apiClient } from '@api/client';
import { useCurrentWorkspace } from '@hooks/workspace';

interface ISharingProps {
  link: string | null;
  previewLink: string | null;
  campaignId: string | null;
  isDisabled: boolean;
  onSubmit(data: {
    users: string[];
    groups: string[];
    message?: string;
  }): Promise<boolean | void>;
  onUpdateLinkName: (v: string) => void;
  linkName: string | undefined;
}

function Sharing({
  link,
  previewLink,
  campaignId,
  isDisabled,
  onSubmit,
  onUpdateLinkName,
  linkName
}: ISharingProps) {
  const [workspace] = useCurrentWorkspace();
  const [isLinkCopied, setIsLinkCopied] = useState<boolean>(false);
  const [isShowMessage, setIsShowMessage] = useState<boolean>(false);
  const [form] = Form.useForm();
  const inviteMutation = useMutation({
    mutationFn: async ({
      userList,
      message
    }: {
      userList: SelectedItem[];
      message: string;
    }) => {
      if (userList.length === 0) return;
      await onSubmit({
        users: userList
          .filter((x) => x.type === 'new' || x.type === 'member')
          .map((x) => x.email),
        groups: userList.filter((x) => x.type === 'group').map((x) => x.id),
        message
      });
    }
  });

  const onCopyLink = () => {
    if (!isDisabled && link) {
      navigator.clipboard.writeText(link);
      setIsLinkCopied(true);
      message.success({
        content: 'Copied link to clipboard',
        className: 'message-dark-modal',
        icon: <SuccessSvg className="anticon" />
      });
    }
  };

  return (
    <div className="share_asset_modal_form">
      <p className="modal_container__copy">Media link</p>
      <div
        className={`modal_container__link_block ${
          isDisabled ? 'modal_container__link_block_disabled' : ''
        }`}
        onClick={onCopyLink}
      >
        <div className="link_row">
          <LinkSvg />
          <span className={`link ${isDisabled ? 'link_disabled' : ''}`}>
            {isDisabled && !link ? 'No access link' : link}
          </span>
        </div>
        {!isDisabled && link && (
          <CopySvg className={isLinkCopied ? 'isLinkCopied' : ''} />
        )}
      </div>
      {!isDisabled && previewLink && (
        <a
          target="_blank"
          href={previewLink}
          className="share_asset_modal_preview-link"
          rel="noreferrer"
        >
          Preview link in new tab
        </a>
      )}
      <Form.Item
        className="share_asset_modal_users-item"
        style={{ marginTop: '12px' }}
      >
        <Input
          placeholder="Add link name"
          onBlur={(e) => {
            const { value } = e.target;
            if (value === linkName) return;
            onUpdateLinkName(value);
          }}
        />
      </Form.Item>
      <Divider className="modal_container_divider" />
      <Form form={form} onFinish={inviteMutation.mutate}>
        <Form.Item
          className="share_asset_modal_users-item"
          label="Add email or username"
          name="userList"
        >
          <InviteMembersSelect
            key={campaignId}
            queryEnabled={!!campaignId}
            membersQueryKey={
              workspace.permissions.listMembers
                ? ['workspace', 'members', 'list', workspace.id]
                : ['campaign', 'members', 'list', campaignId]
            }
            membersQueryFn={async ({ pageParam, searchQuery, signal }) => {
              if (workspace.permissions.listMembers) {
                const { data } =
                  await apiClient.workspace.workspaceControllerListMembers(
                    {
                      workspaceId: workspace.id,
                      searchQuery,
                      after: pageParam
                    },
                    { signal }
                  );
                return data;
              }
              const { data } =
                await apiClient.campaign.campaignControllerListMembers(
                  {
                    campaignId: nonNull(campaignId),
                    searchQuery,
                    after: pageParam
                  },
                  { signal }
                );
              return data;
            }}
            groupsQueryKey={
              workspace.permissions.listMembers
                ? ['workspace', 'groups', 'list', workspace.id]
                : ['campaign', 'groups', 'list', campaignId]
            }
            groupsQueryFn={async ({ pageParam, searchQuery, signal }) => {
              if (workspace.permissions.listMembers) {
                const { data } =
                  await apiClient.workspace.workspaceControllerListMemberGroups(
                    {
                      workspaceId: workspace.id,
                      after: pageParam,
                      searchQuery
                    },
                    { signal }
                  );
                return data;
              }
              const { data } =
                await apiClient.campaign.campaignControllerListMemberGroups(
                  {
                    campaignId: nonNull(campaignId),
                    after: pageParam,
                    searchQuery
                  },
                  { signal }
                );
              return data;
            }}
          />
        </Form.Item>
        {isShowMessage && (
          <Form.Item
            className="share_asset_modal_users-item"
            name="message"
            rules={[{ required: true, message: 'Message required.' }]}
          >
            <Input.TextArea placeholder="Add custom message" />
          </Form.Item>
        )}
        <Row
          wrap={false}
          gutter={12}
          className="share_asset_modal_users-bottom"
          align="middle"
        >
          <Col className="share_asset_modal_form-left">
            <Form.Item
              name="addMessage"
              className="share_asset_modal_checkbox"
              valuePropName="checked"
            >
              <Checkbox
                className="b-checkbox-blue"
                onChange={(e) => setIsShowMessage(e.target.checked)}
              >
                Add a message
              </Checkbox>
            </Form.Item>
          </Col>
          <Col className="share_asset_modal_form-right">
            <Button
              type="primary"
              htmlType="submit"
              className="submit_form submit_form_btn"
              size="middle"
              loading={inviteMutation.isLoading}
            >
              Send link
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default Sharing;
