import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTypedSelector } from '@hooks';
import { useDispatch } from 'react-redux';
import { Tooltip } from 'antd';
import { ReactComponent as OrganizationSvg } from '@assets/icons/organization.svg';
import { ReactComponent as PlusSvg } from '@assets/icons/plus.svg';
import { ReactComponent as ArrowDownSvg } from '@assets/icons/arrow-down.svg';
import { useOrganization } from '@components/OrganizationBoundary';
import { useCurrentWorkspace } from '@hooks/workspace';
import { toggleWorkspaceCreationModal } from '@redux/actions/modalAction';
import ImageComponent from '@components/ImageComponent';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { setCurrentCampaign } from '@redux/actions/campaignAction';
import OrganizationsDropdownMenu from '@components/Organizations/OrganizationsDropdownMenu/OrganizationsDropdownMenu';
import { useAuth } from '@hooks/useAuth';

function WorkspacesPanel() {
  const workspaceList = useTypedSelector(
    ({ workspace }) => workspace.workspaceList
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useAuth(true);
  const [currentWorkspace, setWorkspace] = useCurrentWorkspace(false);
  const [pendingWorkspaceId, setPendingWorkspaceId] = useState<
    string | undefined | false
  >(false);
  const workspaceId =
    pendingWorkspaceId !== false ? pendingWorkspaceId : currentWorkspace?.id;
  const { currentOrganization } = useOrganization();

  return (
    <div className="workspaces_panel">
      <OrganizationsDropdownMenu>
        <div className="workspaces_panel__head">
          <OrganizationSvg className="organization_house" />
          <ArrowDownSvg className="organization_arrow" />
        </div>
      </OrganizationsDropdownMenu>
      <OverlayScrollbarsComponent
        className="workspaces_panel__content"
        style={{ flexGrow: 1, width: '100%' }}
      >
        <div className="workspaces-list-container">
          <span className="workspaces-wrapper">
            {workspaceList
              .filter(
                (el) => el.organizationId === currentOrganization.entity?.id
              )
              .map((el, idx) => {
                const isActive = el.id === workspaceId;
                return (
                  <div
                    key={idx}
                    className={
                      isActive
                        ? 'workspace_item workspace_item__selected'
                        : 'workspace_item'
                    }
                    onClick={() => {
                      if (isActive) return;
                      dispatch(setCurrentCampaign(null));
                      navigate(`/dashboard/${el.id}`);
                      setPendingWorkspaceId(el.id);
                      setWorkspace(el.id).finally(() => {
                        setPendingWorkspaceId(false);
                        // toast(
                        //   <NewWorkspaceToast
                        //     userName={user.firstName}
                        //     workspaceName={el.name}
                        //     closeToast={() => null}
                        //   />,
                        //   {
                        //     hideProgressBar: true,
                        //     className: 'new_workspace_toast'
                        //   }
                        // );
                      });
                    }}
                  >
                    <ImageComponent
                      src={el.picture.url}
                      id={el.id}
                      width={40}
                      height={40}
                      name={el.name}
                      alt={`${el.name} avatar`}
                      types={['png', 'webp']}
                    />
                  </div>
                );
              })}
          </span>
          {currentOrganization.entity?.permissions.createWorkspaces && (
            <Tooltip
              placement="right"
              title="New Workspace"
              arrowPointAtCenter
              overlayClassName="new_workspace_tooltip"
            >
              <div
                className="workspace_item workspace_item__add"
                onClick={() => {
                  dispatch(
                    toggleWorkspaceCreationModal({
                      visible: true,
                      isEditing: false
                    })
                  );
                }}
              >
                <PlusSvg />
              </div>
            </Tooltip>
          )}
        </div>
      </OverlayScrollbarsComponent>
      {/* TODO:  integrate hubspot chat */}
      {/* <div className="workspaces_panel__content_chat">
        <ChatSvg />
      </div> */}
    </div>
  );
}

export default WorkspacesPanel;
